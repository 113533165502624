.Wrap {
	display: block;
	padding: 25px;
	border-radius: 6px;
	background: #fff;
	border: 1px solid rgba(201, 201, 204, 0.48);
	will-change: filter;
	color: black;
	text-decoration: none;
	transition: all 0.1s;
}
.Wrap:hover {
	box-shadow: 1px 1px 3px 0 rgb(51 51 51 / 32%);
	background: #f6f7f8;
}

.Image {
	background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 0 0 30px;
    width: 65px;
    height: 65px;
    border-radius: 3px;
    float: right;
}

.Title {
	font-size: 17px;
    font-weight: 600;
    line-height: 1.5em;
	margin: 0 0 10px 0;
}

.Description {
	margin: 0 0 20px 0;
    font-size: 15px;
    line-height: 1.55em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.Anchor {
	display: block;
    font-size: 15px;
    line-height: 1em;
    color: #888 !important;
    border: 0 !important;
    padding: 0 !important;
}