.cursor {
	z-index: 998;
	opacity: 0.1;
	width: 2rem;
	height: 2rem;
	top: 0;
	left: 0;
	background: black;
	position: fixed;
	border-radius: 50%;
	transition: width 0.1s ease-in-out, height 0.1s ease-in-out, color 0.1s ease-in-out;
}

.cursor__wrap {
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 995;
	transition: opacity 0.1s ease-in-out;
}

@media (hover: none)
{
	.cursor__wrap {
		display: none !important;
	}
}

.cursor-dot {
	z-index: 999;
	opacity: 1;
	width: 0rem;
	height: 0rem;
	background: #eb5a68;
	border-radius: 50%;
	transition: width 0.1s ease-in-out, height 0.1s ease-in-out;
}

.cursor-hover .cursor-dot,
.cursor-hover .cursor {
	background: #eb5a68;
	width: 1rem;
	height: 1rem;
}

.cursor-hover .cursor-dot {
	box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.7);
}
