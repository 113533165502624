.Wrapper.Interactive {
	cursor: none;
}
.Wrapper.NonIteractive {
	cursor: none;
}

.Background {
	position: relative;
	width: 100%;
	height: 100%;
}
.Image {
	left: 10px;
	top: 10px;
	width: calc(100% - 20px);
	height: calc(100% - 20px);
	position: absolute;
	object-fit: cover;
	overflow: hidden;
}
.Image img {
	width: 100%;
	height: 100%;
	transition: transform 0.7s ease-out;
	object-fit: cover;
}

.Overflow {
	background: #000000;
	opacity: 0;

	width: calc(100% - 20px);
	height: calc(100% - 20px);
	position: absolute;

	top: 10px;
	left: 10px;

	transition: opacity 0.5s;
}
.Wrapper.Interactive:hover .Overflow {
	opacity: 0.65;
}
.Wrapper.Interactive:hover .Image img {
	transform: scale(1.1);
}

.Title {
	color: white;
	position: absolute;
	bottom: 20px;
	left: 20px;
	font-size: 1.4em;
	text-transform: uppercase;
	opacity: 0;
	transition: opacity 0.4s, transform 0.4s;

	transform: translateY(25%);

	font-family: 'Oswald', sans-serif;
}
.Wrapper.Interactive:hover .Title {
	opacity: 1;
	transform: translateY(0%);
}
