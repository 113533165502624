.Table {
	margin-top: 10px;
	margin-bottom: 10px;
	min-width: 100%;
	border-collapse: collapse;
}
.Table, .Table th, .Table td {
	border: 1px solid #dbdbe2;
}
.Table td {
	padding: 10px;
}