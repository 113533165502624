.Background {
	position: relative;
	width: 100%;
	height: 100%;
	pointer-events: none;
	user-select: none;
}

.Overflow {
	background: #d6d6d6;

	width: calc(100% - 20px);
	height: calc(100% - 20px);
	position: absolute;

	top: 10px;
	left: 10px;

	transition: opacity 0.5s;
}