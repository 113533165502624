.Warining {
	display: block;
	padding: 15px;
	background: #ffdddd;
	border-radius: 5px;
	border-left: 5px solid rgb(255, 70, 66);
	overflow: hidden;
}

.Warining img {
	height: 2em;
	color: rgb(255, 70, 66);
	display: block;
	float: left;
	width: auto;
}

.Wrap {
	display: block;
	margin-left: 20px;
	width: calc(100% - 2em - 20px);
	float: left;
}

.Title {
	display: block;
	font-size: 1.1em;
	margin-bottom: 5px;
	font-weight: bold;
}

.Message {
	display: block;
}