body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #707070;
  cursor: none;
}

h1, h2, h3, h4, h5, h6
{
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: 'Oswald', sans-serif;
  font-weight: lighter;
  color: black;
}

a {
  color: #333;
  cursor: none;
}
a:hover {
  color: black;
  cursor: none;
}

img {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

mark {
  background-color: #ffeb3b;
  color: black;
}

.App {
  margin-bottom: 10rem;
}
