.Lightbox {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0px;
	left: 0px;
	background: rgb(0 0 0 / 70%);
	z-index: 300;
}

.Lightbox.anim {
	animation: show-opacity 0.3s;
}

.Image {
	max-width: 95vw;
	max-height: 85vh;
	width: auto;
	height: auto;
	margin: 50vh auto auto auto;
	transform: translateY(-50%);
	display: block;
}

.Image.anim {
	animation: pop-in 0.3s;
}
.Protection {
	position: fixed;
	z-index: 301;
	width: 100%;
	height: 100vh;
	top: 0px;
	left: 0px;
}

.Close {
	font-size: 3vw;
	position: fixed;
	color: white;
	right: 1rem;
	top: -1rem;
	padding: 0px;
	margin: 0px;
	display: block;
	cursor: none;
	z-index: 301;
}

.Prev {
	position: fixed;
	width: 4.5rem;
	height: 4.5rem;
	top: 50%;
	left: 2vw;
	transform: translateY(-50%);
	cursor: none;
	z-index: 301;
	transition: transform 0.1s ease-out;
	max-width: 10vw;
	max-height: 10vw;
}
.Prev:hover {
	transform: translate(-15%,-50%);
}

.Next {
	position: fixed;
	width: 4.5rem;
	height: 4.5rem;
	top: 50%;
	right: 2vw;
	transform: translateY(-50%) rotate(180deg);
	cursor: none;
	z-index: 301;
	transition: transform 0.1s ease-out;

	max-width: 10vw;
	max-height: 10vw;
}
.Next:hover {
	transform: translate(15%,-50%) rotate(180deg);
}

@keyframes show-opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes pop-in {
	0% {
		transform: translateY(-50%) scale(0) ;
	}
	100% {
		transform: translateY(-50%) scale(1);
	}
}
