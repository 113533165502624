.Quote {
	display: block;
	padding: 15px;
	background: #ecf0f1;
	border-radius: 5px;
	overflow: hidden;
}

.Quote blockquote {
	color: #2c3e50;
	font-size: 36px;
	line-height: 100%;
	position: relative;
	font-family: Georgia, serif;
	width: 50%;
	margin: 1em auto;
}

.Quote blockquote::before {
	content: '\201C';
	position: absolute;
	top: -10px;
	left: 0;
	font-family: Arial, sans-serif;
	font-size: 82px;
	font-style: normal;
	color: #707070;
}
.Quote blockquote::after {
	content: '\201C';
	position: absolute;
	bottom: -60px;
	right: 0;
	font-family: Arial, sans-serif;
	font-size: 82px;
	font-style: normal;
	color: #707070;
}

.Quote span {
	color: #707070;
	font-weight: bold;
	width: 50%;
	text-align: right;
	display: block;
	margin: auto;
}