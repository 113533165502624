.TopLine {
	background: white;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 8vh;
	z-index: 7;
	border-bottom: 2px solid black;
}

.Logo {
	display: inline-block;
	width: auto;
	height: 4.6vh;
	margin: 2.4vh 1vh 1vh 15px;
}

.BurgerButton {
	background: #707070;
	display: block;
	position: relative;

	float: right;
	margin-top: calc(5vh - 5px);
	margin-right: 1rem;

	height: 3px;
	width: 5vh;

	transition: all 0.3s;
}
.BurgerButton::before {
	content: '';
	display: block;
	height: 3px;
	width: 5vh;
	background: #707070;
	position: absolute;
	transform: translateY(-0.7rem);
	transition: all 0.3s;
}
.BurgerButton::after {
	content: '';
	display: block;
	height: 3px;
	width: 5vh;
	background: #707070;
	position: absolute;
	transform: translateY(0.7rem);
	transition: all 0.3s;
}

.BurgerButton.Opened {
	background: transparent;
}
.BurgerButton.Opened::before {
	transform: rotate(45deg);
	transform-origin: 50% 50%;
	animation: make-cross-before 0.3s;
}
.BurgerButton.Opened::after {
	transform: rotate(-45deg);
	transform-origin: 50% 50%;
	animation: make-cross-after 0.3s;
}

@keyframes make-cross-before {
	0% {
		transform: translateY(-0.7rem);
	}
	50% {
		transform: translateY(0rem) rotate(0deg);
	}
	100% {
		transform: rotate(45deg);
	}
}

@keyframes make-cross-after {
	0% {
		transform: translateY(0.7rem);
	}
	50% {
		transform: translateY(0rem) rotate(0deg);
	}
	100% {
		transform: rotate(-45deg);
	}
}

.Nav {
	position: fixed;
	background: #1E1E1E;
	left: 0px;
	top: calc(8vh + 2px);
	width: 100%;
	height: calc(100vh - 8vh - 2px);
	z-index: 6;
	transform: translateY(calc(-100% - 2px));
	transition: transform 0.3s ease-in-out;
	padding: 0.5rem 1.5rem;
	overflow: hidden auto;
	box-sizing: border-box;
}

.Nav.Opened {
	transform: translateY(0px);
}

.NavLink {
	display: block;
	text-decoration: none;
	text-transform: uppercase;
	margin: 1rem 0px;
	font-size: 1.5rem;
	font-family: 'Oswald', sans-serif;
	box-sizing: border-box;
	font-weight: lighter;
}
.NavLink:not(.ActiveNavLink) {
	color: white !important;
}

.NavLinkButton {
	background: black;
	text-decoration: none;
	color: white;
	font-family: 'Oswald', sans-serif;
	width: 100%;
	display: block;
	box-sizing: border-box;
	text-align: center;
	padding: 0.5rem;
	font-size: 1.5rem;
	text-transform: uppercase;
	margin: 0.5rem 0px;
	border-radius: 3px;
	font-weight: lighter;
}