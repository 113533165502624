.Header {
	margin: 1rem 0px 4rem 0px;
}

.Nav {
	float: right;
	height: 2rem;
    line-height: 2rem;
	font-family: 'Oswald', sans-serif;
}

.NavLink {
	text-decoration: none;
	display: inline-block;
	margin: 10px 1rem;
	color: #707070;
	text-transform: uppercase;
}
.NavLink:hover {
	color: #505050;
}
.NavLink:last-of-type {
	margin-right: 0px;
}
.NavLink:first-of-type {
	margin-left: 0px;
}
.ActiveNavLink {
	color: #353535;
}

.Logo {
	height: 2.5rem;
	width: auto;
}